#page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow-y: auto;
    padding-block: 1rem 2rem;
    position: fixed;
    width: 100%;
}


@media screen and (orientation: portrait) {
    #page {
        --nav-box-height: calc((2 * var(--nav-height)) + 0.5rem); /* 0.5rem is --nav-gap value for portraits */

        flex-direction: column-reverse;
        overflow-y: initial;
        padding-block: 0 1rem;
    }

    #page > #content {
        height: calc(100% - var(--nav-box-height));
        overflow-y: auto;
    }

    #page > #nav { height: var(--nav-box-height); }
}