#items {
    display: grid;
    width: 100%;
}
#items > .item {
    background-color: rgba(255, 255, 255, 0.125);
    border-radius: 0.25rem;
    display: grid;
    gap: 0.5rem;
}
#items > .item > .image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
#items > .item > .description,
#items > .item > .title { padding-inline: 0.5rem; }
#items > .item > .description { font-weight: 350; }
#items > .item > .title {
    font-size: 0.875rem;
    font-weight: 500;
}

#items.grid { grid-template-rows: auto; }
#items.grid > .item {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 1fr 1fr;
    overflow: hidden;
}
#items.grid > .item > * {
    grid-column-end: 2;
    grid-column-start: 1;
}
#items.grid > .item > .image {
    border-radius: 0.5rem;
    grid-row-end: 3;
    grid-row-start: 1;
    overflow: hidden;
    word-break: break-all;
}
#items.grid > .item > .title {
    grid-row-end: 4;
    grid-row-start: 3;
}
#items.grid > .item > .description {
    grid-row-end: 5;
    grid-row-start: 4;
}
#items.grid > #zero {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 12.5rem;
    justify-content: center;
}



.cta {
    cursor: pointer;
    padding: 0.33rem 1rem;
}



#video-player {
    background: black;
    padding: 1rem;
    overflow-x: hidden;
    position: sticky;
    text-align: center;
    top: calc(0px - (var(--page-block-padding) / 2));
    z-index: 10;
}
#video-player > #close {
    cursor: pointer;
    display: none;
    height: 1.5rem;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.5rem;
}
#video-player > #close::before {
    background: var(--theme-curtain);
    content: "";
    height: 100%;
    padding: 0.5rem;
    width: 100%;
}
#video-player:hover > #close { display: flex; }
#video-player > #video { aspect-ratio: 2/1; }



@media screen and (orientation: landscape) {
    #items {
        gap: 1rem;
        padding: 1rem;
    }
    #items.grid { grid-template-columns: repeat(6, 1fr); }
    #items.grid > .item { grid-template-rows: 15vh 2.5rem 2.5rem; }
    #items.grid > .item > .description,
    #items.grid > .item > .title {
        max-height: 2.5rem;
        min-height: 2.5rem;
    }
    #items.grid > #zero {
        grid-column-end: 7;
        grid-column-start: 1;
    }


    #video-player > #video { height: 50vh; }
}



@media screen and (orientation: landscape) and (max-width: 1081px) {
    #items.grid { grid-template-columns: repeat(4, 1fr); }
}



@media screen and (orientation: portrait) {
    #items {
        gap: 0.5rem;
        padding: 0.5rem;
    }
    #items.grid { grid-template-columns: repeat(2, 1fr); }
    #items.grid > .item { grid-template-rows: 15vh 10vh; }
    #items.grid > .item:nth-child(5n) {
        grid-column-end: 3 !important;
        grid-column-start: 1 !important;
    }
    #items.grid > .item > .description { display: none; }
    #items.grid > .item > .title {
        max-height: 10vhrem;
        min-height: 10vhrem;
    }
    #items.grid > #zero {
        grid-column-end: 4;
        grid-column-start: 1;
    }


    #video-player { top: 0; }
    #video-player > #video { width: 100%; }
}