#loader {
    align-items: center;
    display: none;
    justify-content: center;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 50;
}
#loader.is-loading { display: flex; }


svg {
    enable-background: new 0 0 50 50;
    height: 5rem;
    width: 2rem;
}
svg path,
svg rect { fill: var(--theme-brand-primary); }

svg rect {
    height: 100% !important;
    width: 25% !important;
}