:root {
    --corner-rounding: 0.33rem;
    --nav-height: 2.5rem;
    --padding-size: 1rem;
    --page-block-padding: 2rem;
}

@media screen and (orientation: landscape) {
    :root {
        --nav-gap: 1rem;
        --padding-size: 1rem;
    }
}

@media screen and (orientation: landscape) {
    :root {
        --nav-gap: 0.5rem;
        --padding-size: 1rem;
    }
}