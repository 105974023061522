/* theme settings */
:root {
    --theme-brand-primary: purple;

    background: var(--theme-background);
    color: var(--theme-text-default);
}

/* cta buttons */
.cta { background: var(--theme-cta-background); }

/* form */
button, input, textarea { background: transparent; }
form { background: var(--theme-cta-background); }


/* light theme related variables */
@media (prefers-color-scheme: dark) {
    :root {
        --theme-background: #272727;
        --theme-cta-background: #3D3D3D;
        --theme-nav-background: #141414;
        --theme-text-default: #c7c7c7;
        --theme-curtain: #27272750;

        color-scheme: dark;
    }

    /* icons */
    .icon.chevron-left { background: url("../images/chevron_left_dark_theme.svg"); }
    .icon.chevron-right { background: url("../images/chevron_right_dark_theme.svg"); }
    .icon.close { background: url("../images/x_dark_theme.svg"); }
    .icon.shuffle { background: url("../images/shuffle_dark_theme.svg"); }
    .icon.search { background: url("../images/search_dark_theme.svg"); }
    .icon.video { background: url("../images/film_dark_theme.svg"); }

    /* media */
    img, video { filter: brightness(.8) contrast(1.2); }
}


/* light theme related variables */
@media (prefers-color-scheme: light) {
    :root {
        --theme-background: #FFFFFF;
        --theme-cta-background: #F5F5F5;
        --theme-nav-background: #e6e6e6;
        --theme-text-default: #383838;
        --theme-curtain: #ffffff50;

        color-scheme: light;
    }

    /* icons */
    .icon.chevron-left { background: url("../images/chevron_left_light_theme.svg"); }
    .icon.chevron-right { background: url("../images/chevron_right_light_theme.svg"); }
    .icon.close { background: url("../images/x_light_theme.svg"); }
    .icon.shuffle { background: url("../images/shuffle_light_theme.svg"); }
    .icon.search { background: url("../images/search_light_theme.svg"); }
    .icon.video { background: url("../images/film_light_theme.svg"); }
}
