.navigation {    
    align-items: center;
    background: var(--theme-nav-background);
    border-radius: 0.5rem;
    display: flex;
    gap: var(--nav-gap);
    margin-inline: 1rem;
    overflow-x: auto;
    padding-inline: 1rem;
}
.navigation img { width: 1rem !important; }




.filter-bar { display: grid; }
.filter-bar form {
    align-items: center;
    border-radius: 2rem;
    display: flex;
    overflow-x: auto;
    padding: 0.25rem 0.5rem !important;
}
.filter-bar form button,
.filter-bar form input {
    border: none;
    outline: none;
}
.filter-bar form > * { flex-shrink: 0; }
.filter-bar form input { width: calc(100% - 1.8rem); }
.filter-bar > .filter {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: 0.25rem;
}
.filter-bar > .filter > .current { width: 5rem; }
.filter-bar > .controls {
    align-items: center;
    display: flex;
    gap: 0.25rem;
}
.filter-bar > .ribbons {
    flex-grow: 1;
    height: var(--nav-height);
    overflow-y: hidden;
}
.filter-bar > .ribbons::-webkit-scrollbar { height: 0; }
.filter-bar > .ribbons > .ribbon,
.filter-bar > .ribbons > .ribbon > * {
    align-items: center;
    display: flex;
    height: inherit;
}
.filter-bar > .ribbons > .ribbon {
    gap: 1rem;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}
.filter-bar > #reset,
.filter-bar > .ribbons > .ribbon > * {
    cursor: pointer;
    font-weight: 625;
}
.filter-bar > #reset.active,
.filter-bar > .ribbons > .ribbon > .active {
    background: var(--theme-nav-background);
    color: var(--theme-brand-primary);
    left: 0;
    padding-inline: 1rem;
    position: sticky;
    right: 0;
}



.pagination {
    border-radius: 0.5rem;
    gap: 0.5rem;
    margin-inline: auto;
    padding-inline: 1rem;
    width: fit-content;
}
.pagination::-webkit-scrollbar { height: 0; }
.pagination > * {
    align-items: center;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    height: var(--nav-height);
    justify-content: center;
}
.pagination > .active { color: var(--theme-brand-primary); }

@media screen and (orientation: landscape) {
    .filter-bar {
        grid-template-columns:  max-content max-content max-content max-content 1fr;
        grid-template-rows: var(--nav-height);
    }

    #filter-bar > #reset {
        grid-column-end: 2;
        grid-column-start: 1;
    }
    #filter-bar > form {
        grid-column-end: 3;
        grid-column-start: 2;
    }
    #filter-bar > .filter {
        grid-column-end: 4;
        grid-column-start: 3;
    }
    #filter-bar > .controls {
        grid-column-end: 5;
        grid-column-start: 4;
    }
    #filter-bar > .ribbons {
        grid-column-end: 6;
        grid-column-start: 5;
    }
    .filter-bar > .ribbons > .ribbon::-webkit-scrollbar { height: 0; }



    .pagination { max-width: 50%; }
}



@media screen and (orientation: portrait) {
    .filter-bar {
        grid-template-columns: 17.5% 1fr 17.5%;
        grid-template-rows: repeat(2, var(--nav-height));
        margin-inline: 0.5rem;
        padding-inline: 0.5rem;
    }

    .filter-bar > #reset {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-row-start: 1;
    }
    .filter-bar > form {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 2;
        grid-row-start: 1;
    }
    .filter-bar > .filter {
        grid-column-end: 4;
        grid-column-start: 3;
        grid-row-end: 2;
        grid-row-start: 1;
        text-align: end;
    }
    #filter-bar > .controls {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 2;
    }
    .filter-bar > .ribbons {
        grid-column-end: 4;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 2;
    }
    
    .filter-bar > .filter > :first-child { display: none; }


    
    .pagination { max-width: 85%; }
}