a { text-decoration: none; }

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.7rem;
    margin: 0;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* icons */
.icon {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1rem;
    position: relative;
    width: 1rem;
}
.icon.fill-space {
    background-size: 50% !important;
    height: 100%;
    width: 100%;
}