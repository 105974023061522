/* all */
*, *::after, *::before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	box-sizing: border-box;
    color: inherit;
	cursor: default;
}

/* media files */
audio, img, video { max-inline-size: 100%; }

/* font size */
@media screen and (min-width: 1280px) { :root { font-size: 12.80px; } }
@media screen and (min-width: 1366px) { :root { font-size: 13.66px; } }
@media screen and (min-width: 1600px) { :root { font-size: 16.00px; } }
@media screen and (min-width: 1920px) { :root { font-size: 19.20px; } }
@media screen and (min-width: 2256px) { :root { font-size: 22.56px; } }
@media screen and (min-width: 2560px) { :root { font-size: 25.60px; } }
@media screen and (min-width: 2736px) { :root { font-size: 27.36px; } }
@media screen and (min-width: 3200px) { :root { font-size: 32.00px; } }
@media screen and (min-width: 3840px) { :root { font-size: 38.40px; } }

/* normalize headings */
h1, h2, h3, h4, h5, h6 {
    all: unset;
    font-size: inherit;
}

/* icons */
.icon {
    background-size: contain !important;
    height: 1rem;
    position: relative;
    width: 1rem;
}